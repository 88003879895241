@import "@/assets/style/_index.scss";

































.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;

    > .img-obj {
        width: 4rem;
        margin-bottom: 0.5rem;
        opacity: 0.2;
    }
}
